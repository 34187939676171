import React from "react";
import { Image } from "antd";
import footer from "../assets/footer.jpg";

const Footer = () => {
  return (
    <footer className="app-footer">
      <Image className="footer-img" src={footer} preview={false} alt="footer" />
    </footer>
  );
};

export default Footer;
