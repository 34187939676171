const generateDeviceID = () => {
  const array = new Uint8Array(12); // 12 bytes = 24 hex characters
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

const groupMessagesByDate = (messages) => {
  if (!Array.isArray(messages)) return [];
  return messages.reduce((groups, message) => {
    const date = new Date(message.createdAt).toDateString(); // Group by current date for simplicity
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});
};

const formatDate = (date) => {
  const today = new Date().toDateString();
  const messageDate = new Date(date).toDateString();

  if (today === messageDate) {
    return "Hôm nay";
  } else if (
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString() ===
    messageDate
  ) {
    return "Hôm qua";
  } else if (
    new Date(new Date().setDate(new Date().getDate() - 2)).toDateString() ===
    messageDate
  ) {
    return "Hôm kia";
  } else {
    // Định dạng DD/MM/YYYY
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Tháng từ 0-11
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }
};

export { generateDeviceID, groupMessagesByDate, formatDate };
