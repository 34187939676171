import React from "react";
import { Image } from "antd";
import neo from "../assets/neo-chat.png";
import { APP_URL } from "../utils/constant";

const GoToChat = () => {
  const goToChat = () => {
    // navigate("/sos-ghtk");
    window.open(`${APP_URL}/neo`, "_blank");
  };
  return (
    <div className="app-go-to-chat">
      <div onClick={() => goToChat()} className="button-chat">
        Chat với NEO
      </div>
      <Image
        onClick={() => goToChat()}
        width={80}
        className="neo-img"
        src={neo}
        preview={false}
        alt="neo"
      />
    </div>
  );
};

export default GoToChat;
