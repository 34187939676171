/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Upload, message } from "antd";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import api from "../api";
import useNotifications from "../hooks/useNotifications";
import { MESSAGE_TYPE, ROLE } from "../utils/constant";
import { ReactComponent as Att } from "../assets/svg/image.svg";
import { ReactComponent as Sent } from "../assets/svg/sent2.svg";
import { ReactComponent as Emoji } from "../assets/svg/emoji.svg";
import { ReactComponent as FileIcon } from "../assets/svg/file.svg";
import { ReactComponent as Emoji2 } from "../assets/svg/emoji2.svg";
import { ReactComponent as Markdown } from "../assets/svg/mark-down.svg";
import OpenChat from "../assets/open.png";
// import ChatBotIcon from "../assets/chatwoot_bot.png";
import ChatBotIcon from "../assets/logo-ghtk.png";

import logoFav from "../assets/logo-fav.png";
import logoGhtk from "../assets/vietnam-airline.png";
import TextArea from "antd/es/input/TextArea";
import EmojiPicker from "./EmojiPicker";
import { formatDate, groupMessagesByDate } from "../utils/common";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BOT_MESSAGE = "Dạ anh/chị cần GHTK hỗ trợ thông tin gì ạ?";

const DATA_AUTO_MESSSAGE = [
  {
    id: 1,
    message: `Vietnam Airllines kính chào quý khách, chúc bạn một ngày mới tốt lành. hãy chọn nội dung mà bạn đang quan tâm để được hỗ trợ giải đáp nhé !`,
    children: [
      {
        id: 2,
        message: `Hoàn/Đổi vé`,
      },
    ],
  },
  {
    id: 2,
    message: `Hoàn/Đổi vé`,
    parent: 1,
    childrenId: 3,
  },
  {
    id: 3,
    message: `Rất tiếc vì bạn không thể thực hiện được chuyến bay của mình. Bạn có thể đổi sang chuyến bay khác phù hợp với kế hoạch của mình hoặc hoàn lại vé. Vui lòng chọn nội dung dưới đây để tôi có thể hỗ trợ bạn chi tiết hơn:`,
    children: [
      {
        id: 4,
        message: `Hoàn vé`,
      },
    ],
  },
  {
    id: 4,
    message: `Hoàn vé`,
    parent: 3,
    childrenId: 5,
  },
  {
    id: 5,
    message: `Thật tiếc khi bạn không thể thực hiện được chuyến đi của mình. Bạn vui lòng lựa chọn lý do hoàn vé:`,
    parent: 2,
    children: [
      {
        id: 6,
        message: `Lý do cá nhân`,
      },
      {
        id: 7,
        message: `Hãng thay đổi lịch bay`,
      },
    ],
  },
  {
    id: 6,
    message: `Lý do cá nhân`,
  },
  {
    id: 7,
    message: `Hãng thay đổi lịch bay`,
  },
];

const Chat = () => {
  const {
    messages,
    setMessages,
    pushMessage,
    pushBotMessage,
    isMoreActionMessage,
    setIsMoreActionMessage,
  } = useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [messageGroups, setMessageGroups] = useState([]);
  const [messageAfterGet, setMessageAfterGet] = useState();
  const [isHaveChatHisory, setIsHaveChatHisory] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isShowToolbar, setIsShowToolbar] = useState(false);
  const endOfMessagesRef = useRef(null);
  const quillRef = useRef(null);
  const [height, setHeight] = useState(window.innerHeight);
  const [limit, setLimit] = useState(500);
  const [offset, setOffset] = useState(0);
  const [firstMessage, setFirstMessage] = useState(false);
  let flag = false;

  useEffect(() => {
    if (!Array.isArray(messageAfterGet)) return;
    if (!messageAfterGet.length) {
      setTimeout(() => {
        const detailMessage = DATA_AUTO_MESSSAGE.find((item) => item.id === 1);
        const newMessage = pushBotMessage(
          detailMessage.message,
          MESSAGE_TYPE.TEXT,
          detailMessage.id
        );
        setMessages((prev) => [...prev, newMessage]);
      }, 1500);
      return;
    }
  }, [messageAfterGet]);

  useEffect(() => {
    if (flag) return;
    flag = true;
    getDataMessage();
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    // Set the height on component mount
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    scroolToBottom();
  }, [isShowChat, isOpen]);

  useEffect(() => {
    if (isMoreActionMessage) {
      getDataMessage();
    }
  }, [isMoreActionMessage]);

  useEffect(() => {
    setMessageGroups(groupMessagesByDate(messages));
    if (!isMoreActionMessage) {
      scroolToBottom();
    }
    setIsMoreActionMessage(false);
  }, [messages]);

  const onClickAutoChat = (chatId) => {
    const detailMessage = DATA_AUTO_MESSSAGE.find((item) => item.id === chatId);
    const newMessage = pushMessage(detailMessage.message, MESSAGE_TYPE.TEXT);
    setMessageValue("");
    setMessages((prev) => [...prev, newMessage]);
    if (detailMessage.childrenId) {
      onSendNextBotMessage(detailMessage.childrenId);
    }
  };

  const onSendNextBotMessage = (childrenId) => {
    setTimeout(() => {
      const detailMessage = DATA_AUTO_MESSSAGE.find(
        (item) => item.id === childrenId
      );
      const newMessage = pushBotMessage(
        detailMessage.message,
        MESSAGE_TYPE.TEXT,
        detailMessage.id
      );
      setMessages((prev) => [...prev, newMessage]);
    }, 1000);
  };

  const scroolToBottom = () => {
    if (endOfMessagesRef.current) {
      setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 50);
    }
  };

  const getDataMessage = async () => {
    const { data } = await api.get(
      `/messages/all?deviceID=${localStorage.getItem(
        "chatDeviceID"
      )}&limit=${limit}&offset=${offset}`
    );
    setMessages(data.docs);
    setMessageAfterGet(data.docs);
    if (data.docs.length > 0) {
      setIsHaveChatHisory(true);
      setIsShowChat(true);
    }
  };

  const sendMessageOnclick = async () => {
    if (messageValue) {
      const newMessage = pushMessage(messageValue, MESSAGE_TYPE.TEXT);
      setMessageValue("");
      setMessages((prev) => [...prev, newMessage]);
    }
  };

  const addEmoji = (emoji) => {
    // setMessageValue(messageValue + emoji.native);
    // //     setShowEmojiPicker(false);
    // Lấy đối tượng Quill từ `ReactQuill` bằng cách sử dụng `ref`
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection(); // Lấy vị trí con trỏ hiện tại

    if (range) {
      const emojiText = emoji.native;

      // Chèn emoji vào vị trí con trỏ
      quill.insertText(range.index, emojiText);

      // Đặt con trỏ sau emoji
      quill.setSelection(range.index + emojiText.length);
    } else {
      // Nếu không có vị trí con trỏ, thêm emoji vào cuối nội dung
      const emojiText = emoji.native;
      quill.insertText(quill.getLength(), emojiText);
      quill.setSelection(quill.getLength()); // Đặt con trỏ ở cuối nội dung
    }

    // Cập nhật giá trị trong `messageValue`
    setMessageValue(quill.root.innerHTML);
  };

  const handleFileUpload = async ({ file, fileList }) => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("file", file);

        const { data } = await api.post("/upload", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const endpoint = data.secure_url;

        const newMessage = pushMessage(endpoint, MESSAGE_TYPE.URL);
        setMessages((prev) => [...prev, newMessage]);
        setFileList([]);
      }
    } catch (error) {
      message.error("Đã có lỗi trong quá trình tải file. Vui lòng thử lại!");
      console.log("Upload img error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const checkIsImage = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const getFileName = (url) => {
    // Tìm vị trí của dấu "-"
    const dashIndex = url.indexOf("-");
    // Lấy tên file từ dấu "-" trở đi
    const fileName = url.substring(dashIndex + 1);
    return fileName;
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = getFileName(url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const modules = {
    toolbar: [["bold", "italic", "underline"]],
  };

  const renderChildContent = (message) => {
    if (!message || !message.idAutoMessage) return <></>;
    const childMess = DATA_AUTO_MESSSAGE.find(
      (item) => parseInt(item.id) === parseInt(message.idAutoMessage)
    );
    if (!childMess) return <></>;
    const listChilds = childMess.children;
    return listChilds.map((item, index) => {
      return (
        <div
          key={`${index}-${item.id}`}
          className="child-message-auto-item"
          onClick={() => {
            onClickAutoChat(item.id);
          }}
        >
          {item.message}
        </div>
      );
    });
  };

  return (
    <div className={`chat-wrapper-screen`}>
      <div
        className={`chat-area open`}
        style={{
          height: `${height}px`,
        }}
      >
        <div className="chat-area-message-header">
          <div className="desktop-view">
            <div className="chat-area-message-header-logo">
              <Image src={logoGhtk} alt="app-logo" preview={false} />
            </div>
            {/* <div className="chat-area-message-header-text">
              <div className="font-medium text-base leading-4 flex items-center text-black-900">
                <span className="mr-1">Chăm sóc khách hàng</span>{" "}
              </div>
              <p className="text-xs mt-1 leading-3 text-black-700">
                Giaohangtietkiem
              </p>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-hidden rounded-b-lg bg-slate-25 dark:bg-slate-800 chat-area-message-body">
          <div className="flex flex-1 overflow-auto chat-area-message-body--message">
            <div ref={endOfMessagesRef} />
            {Object.keys(messageGroups)
              .reverse()
              .map((date, index) => (
                <div key={index} className="message-group">
                  <div className="time-send">{formatDate(date)}</div>
                  {messageGroups[date].map((message, idx) => {
                    return (
                      <div
                        key={idx}
                        className={
                          message.role === ROLE.USER
                            ? "user-message"
                            : "system-message"
                        }
                      >
                        {/* Hiển thị tin nhắn là text và quyền là admin */}
                        {message.type === MESSAGE_TYPE.TEXT &&
                          message.role !== ROLE.USER && (
                            <div className="first-bot-block">
                              <div className="chat-bubble-bot-avatar">
                                <Image
                                  src={ChatBotIcon}
                                  preview={false}
                                  width={30}
                                />
                              </div>
                              <div
                                className={`group_message ${
                                  message.idAutoMessage && "auto-mess"
                                }`}
                              >
                                {/* <div className="message-title__sender">
                                  {message.role === ROLE.USER ? "" : "GHTK"}
                                </div> */}
                                <div
                                  className={`chat-bubble ${
                                    message.idAutoMessage && "have-child"
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: message.message,
                                  }}
                                />

                                <div className="child-message-auto">
                                  {renderChildContent(message)}
                                </div>
                              </div>
                              <div></div>
                            </div>
                          )}

                        {/* Hiển thị tin nhắn là text và quyền là user */}
                        {message.type === MESSAGE_TYPE.TEXT &&
                          message.role === ROLE.USER && (
                            <div className="group_message">
                              {/* <div className="message-title__sender">
                                {message.role === ROLE.USER ? "" : "GHTK"}
                              </div> */}
                              <div
                                className="chat-bubble"
                                dangerouslySetInnerHTML={{
                                  __html: message.message,
                                }}
                              />
                            </div>
                          )}
                        {/* Hiển thị tin nhắn là url và quyền là admin */}
                        {message.type === MESSAGE_TYPE.URL &&
                          message.role !== ROLE.USER && (
                            <div className="first-bot-block">
                              <div className="chat-bubble-bot-avatar">
                                <Image
                                  src={ChatBotIcon}
                                  preview={false}
                                  width={30}
                                />
                              </div>

                              <div className="group_message">
                                {/* <div className="message-title__sender">
                                  {message.role === ROLE.USER ? "" : "GHTK"}
                                </div> */}
                                <div className="chat-bubble">
                                  {checkIsImage(message.message) ? (
                                    <Image
                                      src={message.message}
                                      preview={true}
                                      style={{
                                        maxWidth: "380px",
                                      }}
                                    />
                                  ) : (
                                    <div className="file-wraper">
                                      <div className="file-icon">
                                        <FileIcon />
                                      </div>
                                      <div className="file-info">
                                        <div className="file-name">
                                          {getFileName(message.message)}
                                        </div>
                                        <div
                                          className="file-download"
                                          onClick={() => {
                                            downloadFile(message.message);
                                          }}
                                        >
                                          Tải xuống
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        {/* Hiển thị tin nhắn là url và quyền là user */}
                        {message.type === MESSAGE_TYPE.URL &&
                          message.role === ROLE.USER && (
                            <div className="chat-bubble">
                              {checkIsImage(message.message) ? (
                                <Image
                                  src={message.message}
                                  preview={true}
                                  style={{
                                    maxWidth: "380px",
                                  }}
                                />
                              ) : (
                                <div className="file-wraper">
                                  <div className="file-icon">
                                    <FileIcon />
                                  </div>
                                  <div className="file-info">
                                    <div className="file-name">
                                      {getFileName(message.message)}
                                    </div>
                                    <div
                                      className="file-download"
                                      onClick={() => {
                                        downloadFile(message.message);
                                      }}
                                    >
                                      Tải xuống
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
              ))}
            {/* Reference for scrolling */}
          </div>
          <div className="relative z-50 rounded-lg chat-area-message-body--input">
            <div className="chat-message__input">
              <div className="compile-content">
                <div className="compile-content__editer">
                  <div className="editor-message">
                    <ReactQuill
                      ref={quillRef}
                      className={isShowToolbar ? "show" : "hide"}
                      theme="snow"
                      value={messageValue}
                      onChange={(e) => {
                        if (e === "<p><br></p>") {
                          setMessageValue("");
                        } else {
                          setMessageValue(e);
                        }
                      }}
                      modules={modules}
                      placeholder="Nhập câu hỏi của quý khách tại đây..."
                    />
                  </div>
                </div>
                <div className="menu-footer-icon">
                  <div
                    title="Soạn thảo văn bản"
                    className="footer-icon"
                    onClick={() => {
                      setIsShowToolbar(!isShowToolbar);
                    }}
                  >
                    <Markdown />
                  </div>
                  {/* <div
                      className="footer-icon"
                      onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                    >
                      <Emoji2 /> */}
                  <div className="emoji-picker-wrapper" title="Chọn Emoji">
                    {showEmojiPicker && (
                      <EmojiPicker
                        showEmojiPicker={showEmojiPicker}
                        onEmojiSelect={addEmoji}
                        callBackFn={() => {
                          setShowEmojiPicker(!showEmojiPicker);
                        }}
                      />
                    )}
                    <Button
                      onClick={() => {
                        setShowEmojiPicker(!showEmojiPicker);
                      }}
                    >
                      <Emoji2 />
                    </Button>
                  </div>
                  {/* </div> */}
                </div>
                <div className="footer-view__rep">
                  {!messageValue ? (
                    <Upload
                      title="Tải ảnh/file"
                      className="button-upload"
                      fileList={fileList}
                      onChange={handleFileUpload}
                      beforeUpload={() => false} // Prevent automatic upload
                    >
                      <Button icon={<Att />}></Button>
                    </Upload>
                  ) : (
                    <div
                      title="Gửi tin nhắn"
                      className="btn-send"
                      onClick={sendMessageOnclick}
                    >
                      <Sent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
