import React from "react";
import { Carousel, Image } from "antd";
// import section1 from "../assets/Banner.jpg";
import section1 from "../assets/banner1.jpg";
import booking from "../assets/booking.png";
import booking1 from "../assets/booking1.png";
import section2 from "../assets/map.png";
import section3 from "../assets/sec3.png";
import section4 from "../assets/item4.png";
import section5 from "../assets/item5.jpg";
import carousel1 from "../assets/images/banner1.webp";
import carousel2 from "../assets/images/banner2.webp";
import carousel3 from "../assets/images/banner3.webp";
import carousel4 from "../assets/images/banner4.webp";
import carousel5 from "../assets/images/banner5.jpg";
import carousel6 from "../assets/images/banner6.jpg";

const contentStyle = {
  margin: 0,
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};
const Main = () => {
  const arrBanner = [
    carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
  ];
  return (
    <main className="app-main">
      <section
        className="section-carousel"
        style={{
          position: "relative",
        }}
      >
        <Carousel
          className="home-carousel"
          draggable
          effect="fade"
          fade
          dots={{
            className: "carousel-dots",
          }}
          autoplay
        >
          {arrBanner.map((item, index) => (
            <div className="carousel-item">
              <div
                className="carousel-item--image"
                style={{
                  backgroundImage: `url(${item})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
            </div>
          ))}
        </Carousel>
        <div className="container-booking">
          <div className="container-booking-img">
            <Image
              className="section-img"
              src={booking}
              preview={false}
              alt="banner"
            />
          </div>
        </div>
        <div className="container-booking-mobile">
          <Image
            className="section-img"
            src={booking1}
            preview={false}
            alt="banner"
          />
        </div>
      </section>
      {/* <section className="section1 mb-20">
        <div className="container-img">
          <Image
            className="section-img"
            src={section1}
            preview={false}
            alt="banner"
          />
        </div>
      </section> */}
      <section className="app-container section2 mb-20">
        <Image
          className="section-img"
          src={section2}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="app-container section3 mb-20">
        <Image
          className="section-img"
          src={section3}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="app-container section4 mb-20">
        <Image
          className="section-img"
          src={section4}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="app-container section5 mb-10">
        <Image
          className="section-img"
          src={section5}
          preview={false}
          alt="banner"
        />
      </section>
    </main>
  );
};

export default Main;
