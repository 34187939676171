import React from "react";
import { Image } from "antd";
import Logo from "../assets/VNA_logo_vn.webp";
import LogoMobile from "../assets/logo_vna-mobile.png";
import skyeam from "../assets/skyteam.webp";
import iconSearch from "../assets/icon_search.png";
import beard from "../assets/bread.png";
import lotus from "../assets/logo_lotusmiles-2x.webp";

const Header = () => {
  return (
    // <header className="app-container app-header">
    //   <div className="elementor-row">
    //     <div className="logo-blk elementor-column">
    //       <Image className="logo" src={Logo} preview={false} alt="logo" />
    //     </div>
    //     <div className="menu-blk elementor-column">
    //       <Image className="menu" src={Menu} preview={false} alt="menu" />
    //     </div>
    //     <div className="emty-blk elementor-column"></div>
    //   </div>
    // </header>
    <header className="app-container app-header-vietnamairline">
      <div className="navbar-logo">
        <div className="vnalogo">
          <div className="mobile-menu">
            <Image
              className="logo"
              width={38}
              src={beard}
              preview={false}
              alt="logo"
            />
          </div>
          <div className="header-logo">
            <Image
              className="logo"
              width={230}
              src={Logo}
              preview={false}
              alt="logo"
            />
            <Image
              className="logo-mobile"
              width={230}
              src={LogoMobile}
              preview={false}
              alt="logo"
            />
          </div>
          <div className="header-skyteam-logo">
            <Image
              width={35}
              className="skyeam"
              src={skyeam}
              preview={false}
              alt="logo"
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div className="headernav">
          <div className="site-top">
            <ul className="register">
              {/* <li className="login-lostusmiles">
                <div className="hd-support">
                  <p>TRỢ GIÚP</p>
                </div>
              </li>
              <li className="login-lostusmiles">
                <div
                  className="hd-support hd-support1"
                  style={{
                    borderLeft: "1px solid #ccc",
                  }}
                >
                  <p>ĐĂNG KÝ</p>
                </div>
              </li>
              <li className="search-icon">
                <i
                  style={{
                    display: "block",
                    backgroundImage: `url(${iconSearch})`,
                    backgroundRepeat: "no-repeat",
                    verticalAlign: "top",
                    backgroundSize: "13px",
                    width: "15px",
                    height: "15px",
                    top: "5px",
                    marginTop: 5,
                    marginLeft: 5,
                    marginRight: 5,
                  }}
                ></i>
              </li> */}
              <li className="lang-opt">
                <div className="hd-support hd-support3">
                  <p>Vietnam - Tiếng Việt</p>
                </div>
              </li>
            </ul>
          </div>
          <div className="nav-bar">
            <div className="nav-bar--item">
              <span>Lên kế hoạch</span>
            </div>
            <div className="nav-bar--item">
              <span>Thông tin hành trình</span>
            </div>
            <div className="nav-bar--item">
              <span>Mua vé & Sản phẩm khác</span>
            </div>
            <div className="nav-bar--item">
              <span>Lotusmiles</span>
            </div>
          </div>
        </div>
        <div className="lotus-image">
          <Image
            width={135}
            className="skyeam"
            src={lotus}
            preview={false}
            alt="logo"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
